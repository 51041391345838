/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../../components/seo"
import Page from "../../layouts/page"

import { Link } from "gatsby"

const InfoPage = () => {
  return <Page>
    <div>
      <div className="contain page-body">
        <ul class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><span>Information</span></li>
        </ul>

        <h1>Information</h1>

        <ul>
          <li><Link to="/info/payments">Payments</Link></li>
        </ul>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Information"
    path="/info"
  />
)

export default InfoPage
